
import { Vue, Component } from "vue-property-decorator";
import AuthService from "@/services/auth_service";
import { Roles } from "@/models/user/roles";
import { GenericError } from "@/services/error_service";

@Component
export default class Login extends Vue {
  protected email = "";
  protected password = "";
  protected error = "";
  protected Roles = Roles;
  protected show1 = false;
  protected authService = new AuthService();

  created() {
    this.checkAuth();
  }

  public async handleLogin(): Promise<void> {
    this.error = "";
    try {
      const returnUrl: string | null = (this.$route.query.returnURL as string) ?? null;
      const user: any = await this.authService.login(
        this.email,
        this.password,
        returnUrl
      );
      if (this.$route.query.returnURL) {
        window.location.href = (this.$route.query.returnURL +
          "?redirect=true") as string;
      } else {
        this.$router.push({
          name: "LoggedIn",
          params: {
            user: user,
          },
        });
      }
    } catch (err) {
      if (!err.message || err.statusCode == 500) {
        this.error =
          "Login failed. Please try again. If this message persists, please contact the administrator.";
      } else {
        this.error = err.message;
      }
    }
  }

  protected async checkAuth(): Promise<void> {
    try {
      const user: any = await this.authService.checkAuth();
      this.$router.push("/logged-in");
    } catch (err) {
      if (err.statusCode && err.statusCode == 500) {
        GenericError.popup("Something went wrong, please reload & try again.");
      }
    }
  }
}
